:root {
  --skiba-blue: #004daa;
  --section-bg: #f6f9fe;
}

body {
  position: relative;
  margin: 0;
  font-family: "Roboto-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 85vh;
}

.content-wrapper {
  flex: 1;
}

main {
  margin-top: 140px;
}

section {
  min-height: 80vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
a {
  font-family: "Arial", "Helvetica", Helvetica, Arial, sans-serif;
  text-decoration: none;
}

h1 {
  font-size: 3.5rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
}

img {
  width: 100%;
}

a {
  color: var(--color-primary);
}

.contain {
  object-fit: contain;
}

.text-white a {
  margin-left: -10px;
  color: #ffff;
}

.navbar-style {
  box-shadow: 0 4px 2px -2px grey;
  background-color: white;
}

.card-title {
  font-weight: 700;
  font-size: 1.2rem;
}

.mouse-hover {
  cursor: pointer;
}

.reset-password {
  font-size: 13px;
  margin-left: 6px;
  margin-bottom: 5px;
  color: var(--skiba-blue);
  cursor: pointer;
}

.input-group-text {
  min-width: 100px;
}

.table-header-color {
  background-color: #4f90d1 !important;
  color: white !important;
  font-weight: 500;
}

table {
  --bs-table-striped-bg: #bdcddd !important;
  --bs-table-hover-bg: #edf3f8 !important;
}

.scroll-to-top-button {
  position: fixed;
  right: 5vw;
  bottom: 4vh;
  width: 50px;
  height: 60px;
  background-color: var(--color-ci-red);
  color: white;
  border-radius: 10%;
  cursor: pointer;
  z-index: 999;
  animation: fadeIn 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
